import React, { useContext, useEffect, useState } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { ajax, ajaxTest, copyObjectExcept, decodeAmpersand, getFromStorage, getGlobalizedText, isEmptyStr, trim, trimFields, validate, ValidationResult } from '../shared/utils';
import './css/profile.css';
import { Form, Formik } from 'formik';
import GlobalizedText from '../shared/globalization';
import { useMessage } from '../shared/message';
import { closeModal, showModal, Button } from '../shared/components';
import { VALIDATION_REGEX } from '../shared/regex';
import { INVALID_MESSAGES } from '../shared/yupschema';
import UserInquiryAssignmentComponent from '../shared/components/userInquiryAssignmentComponent';
import { SecurityContext } from '../context/securityContext';
import { UserTypeCode } from '../shared/constants/userTypeCode';

interface UserProfileForm {
	userID: string,
	email: string,
	phoneNumber: string,
	currentPassword: string,	
	newPassword: string,
	cfmPassword: string,
	secImg: {
		category: {
			msgKey: string,
			name: string
		},
		height: number,
		msgKey: string,
		name: string,
		width: number
	},
	secCaption: string,	
	securityQuestion1: string,
	securityQuestion2: string,
	securityQuestion3: string,
	answer1: string,
	answer2: string,
	answer3: string,
	languageCode: string,
	dateFormat: string,
	firstName: string,
	lastName: string,
	birthDate: string,
	jobTitle: string,
	companyCode: string,
	polNumberOrMemberID: string,
	agentSupport: boolean,
	groupAdminSupport: boolean,
	guestSupport: boolean,
	memberSupport: boolean,
	providerSupport: boolean,
	disabled: boolean,
	userTypeRegCode: string
}

let InitialProfile: UserProfileForm = {
	userID: '',
	email: '',
	phoneNumber: '',
	currentPassword: '',	
	newPassword: '',
	cfmPassword: '',
	secImg: {
		category: {
			msgKey: '',
			name: ''
		},
		height: 0,
		msgKey: '',
		name: '',
		width: 0
	},
	secCaption: '',	
	securityQuestion1: '',
	securityQuestion2: '',
	securityQuestion3: '',
	answer1: '',
	answer2: '',
	answer3: '',
	languageCode: '',
	dateFormat: '',
	firstName: '',
	lastName: '',
	birthDate: '',
	jobTitle: '',
	companyCode: '',
	polNumberOrMemberID: '',
	agentSupport: false,
	groupAdminSupport: false,
	guestSupport: false,
	memberSupport: false,
	providerSupport: false,
	disabled: false,
	userTypeRegCode: ''
}

export const ProfileComponent = withView((props: ViewComponentProps) => {
	const {userAuth} = useContext(SecurityContext);
	const allowedUserTypes = [UserTypeCode.PORTAL_ADMIN, UserTypeCode.SECURITY_PORTAL_ADMIN];
	const { messageService, validateProps } = useMessage("unlockUser");
	const [config, setConfig] = useState<any>();
	const [agentSupp, setAgentSupp] = useState<boolean>(false);
	const [dependentSupp, setDependentSupp] = useState<boolean>(false);
	const [groupAdminSupp, setGroupAdminSupp] = useState<boolean>(false);
	const [guestSupp, setGuestSupp] = useState<boolean>(false);
	const [memberSupp, setMemberSupp] = useState<boolean>(false);
	const [providerSupp, setProviderSupp] = useState<boolean>(false);
	const [isHideUnlock, setIsHideUnlock] = useState<boolean>(false);
	const conf = getFromStorage('gwp.config', true);
	const captcha = props.useCaptcha("securityCode");
	const userID: string = props.getParam("userID");

	if (isEmptyStr(userID)) {
		props.setTitle("common.lbl.myProfile");
	} else {
		props.setTitle("common.lbl.editProfile");
	}



	useEffect(() => {
		if (config === undefined) {
			ajax({
				url: '/api/profile/view',
				params: { userID: userID },
				success: (res_view: any) => {
					setConfig(res_view);
					setAgentSupp(res_view.userProfile.agentSupport === undefined ? agentSupp : res_view.userProfile.agentSupport);
					setDependentSupp(res_view.userProfile.dependentSupport === undefined ? dependentSupp : res_view.userProfile.dependentSupport);
					setGroupAdminSupp(res_view.userProfile.groupAdminSupport === undefined ? groupAdminSupp : res_view.userProfile.groupAdminSupport);
					setGuestSupp(res_view.userProfile.guestSupport === undefined ? guestSupp : res_view.userProfile.guestSupport);
					setMemberSupp(res_view.userProfile.memberSupport === undefined ? memberSupp : res_view.userProfile.memberSupport);
					setProviderSupp(res_view.userProfile.providerSupport === undefined ? providerSupp : res_view.userProfile.providerSupport);
				}
			});
		}
	}, [config]);
	if (config === undefined) {
		return <></>;
	}

	function formatError(msg: any, pro: any) {
		let result: any = {};
		if (msg == undefined) {
			return;
		}
		if (msg == "AnswerIncorrect") {
			result.answer = INVALID_MESSAGES.FORGOT_PASSWORD_ANSWER_INCORRECT;
			props.showFieldError(INVALID_MESSAGES.HOMEPAGE_ERROR_FIELDS, result, pro);
		}
		if (msg == "UserNotFound") {
			result.userID = INVALID_MESSAGES.FORGOT_PASSWORD_USERID_NOTFOUND;
			props.showFieldError(INVALID_MESSAGES.HOMEPAGE_ERROR_FIELDS, result, pro);
		}
		if (msg == "ServerError") {
			props.showMessage("error", INVALID_MESSAGES.FORGOT_PASSWORD_SERVER_ERROR);
		}
		if (msg == "UserForbidden") {
			props.showMessage("error", INVALID_MESSAGES.FORGOT_PASSWORD_FORBIDDEN);
			//setHideForm(true);
		}
		if (msg == "QuestionNotSetup") {
			props.showMessage("error", INVALID_MESSAGES.FORGOT_PASSWORD_QUESTION_NOT_SETUP);
			//setHideForm(true);
		}
		if (msg.indexOf("passwordInHistory") >= 0) {
			var errorMessage = props.getGlobalizedText(INVALID_MESSAGES.FORGOT_PASSWORD_IN_HISTORY)
				+ ' ' + msg.substr(msg.indexOf("/") + 1) + ' '
				+ props.getGlobalizedText(INVALID_MESSAGES.FORGOT_PASSWORD_IN_HISTORY_SUFFIX);
			props.showMessage("error", errorMessage);
		}
	}

	const PASSWORD_REGEX: RegExp = new RegExp(config.validationRule);
	let validationProfile = (values: any) => {
		let res: any = {};
		//let birthDateForm: any = parseDate(values.birthDate, props.getDateFormat());
		if (isInArray(config.userProfile.userTypeRegCode, ["01", "10", "11", "12"]) || (isInArray(config.userProfile.userTypeDesc, ["05", "09"]) && isEmptyStr(userID))) {
			if (isEmptyStr(values.firstName)) {
				res = { ...res, ...{ firstName: "regist.msg.err.first.name.empty" } };
			} else if (trim(values.firstName).length > 20) {
				res = { ...res, ...{ firstName: "changeow.msg.info.firstNameTooLong" } };
			}
			if (isEmptyStr(values.lastName)) {
				res = { ...res, ...{ lastName: "com.lastname.req" } };
			} else if (trim(values.lastName).length > 40) {
				res = { ...res, ...{ lastName: "changeow.msg.info.lastNameTooLong" } };
			}
		}
		if (conf.openIdConnect || isInArray(config.userProfile.userTypeRegCode, ["01", "05", "09", "10", "11", "12"])) {
			
			if(conf.openIdConnect && !isEmptyStr(config.userProfile.email) && isEmptyStr(values.email)){
				res = { ...res, ...{ email: "common.msg.emailReq" } };
			}
			if (!isEmptyStr(values.email) && VALIDATION_REGEX.EMAIL.test(trim(values.email)) === false) {
				res = { ...res, ...{ email: "regist.msg.err.email.irregular" } };
			}			
			

		}
		if (isInArray(config.userProfile.userTypeRegCode, ["01", "05", "09", "10", "11", "12"]) && !isEmptyStr(values.phoneNumber)) {
			if (!isEmptyStr(values.phoneNumber) && VALIDATION_REGEX.PHONE_NUMBER.test(trim(values.phoneNumber)) === false) {
				res = { ...res, ...{ phoneNumber: "regist.msg.err.phone.number.irregular" } };
			}
		}
		if (isInArray(config.userProfile.userTypeRegCode, ["05", "09"]) && !isEmptyStr(values.jobTitle)) {
			if (!isEmptyStr(values.jobTitle)) {
				if (trim(values.jobTitle).length > 50) {
					res = { ...res, ...{ jobTitle: "regist.msg.err.job.title.too.long" } };
				}
				if (trim(values.jobTitle).length < 3) {
					res = { ...res, ...{ jobTitle: "regist.msg.err.job.title.too.short" } };
				}
			}
		}

		if(conf.openIdConnect === false){
			if ((isEmptyStr(userID) && config.userProfile.needUpdatePwd) || ((isEmptyStr(userID) || config.editSelfProfile) && (!(isEmptyStr(values.currentPassword) && isEmptyStr(values.newPassword) && isEmptyStr(values.cfmPassword))))) {
				if (isEmptyStr(values.currentPassword)) {
					res = { ...res, ...{ currentPassword: "regist.msg.err.current.password.empty" } };
				} else if (trim(values.currentPassword).length < config.pwdMinLength) {
					res = { ...res, ...{ currentPassword: `${props.getGlobalizedText("regist.msg.err.password.too.short")} ${config.pwdMinLength} ${props.getGlobalizedText("regist.msg.err.password.suffix")}` } };
				} else if (trim(values.currentPassword).length > config.pwdMaxLength) {
					res = { ...res, ...{ currentPassword: `${props.getGlobalizedText("regist.msg.err.password.too.long")} ${config.pwdMaxLength} ${props.getGlobalizedText("regist.msg.err.password.suffix")}` } };
				} else if (PASSWORD_REGEX.test(trim(values.currentPassword)) === false) {
					res = { ...res, ...{ currentPassword: "regist.msg.err.password.incorrect" } };
				} else {
					let paramsMyProfile = copyObjectExcept(copyObjectExcept(values, true, "disabled"), true, "userID");
					let params = (isEmptyStr(userID) || config.editSelfProfile) ? paramsMyProfile : values;
					let data = {
						...trimFields(params)
					};
					ajax({
						url: '/api/profile/authenticate',
						data: data,
						method: 'post',
						success: (res_a: any) => {
							if (res_a.result === true) {
								return true
							} else {
								return new ValidationResult("currentPassword", "regist.msg.err.password.incorrect");
							}
						},
						fail: (data, message) => {
							return new ValidationResult("currentPassword", "regist.msg.err.password.incorrect");
						}
					});
				}
			}
		}

		
		if (((isEmptyStr(userID) || config.editSelfProfile) && config.userProfile.needUpdatePwd) || !(isEmptyStr(values.currentPassword) && isEmptyStr(values.newPassword) && isEmptyStr(values.cfmPassword))) {
			if (isEmptyStr(values.newPassword)) {
				res = { ...res, ...{ newPassword: "regist.msg.err.new.password.empty" } };
			} else if (trim(values.newPassword).length < config.pwdMinLength) {
				res = { ...res, ...{ newPassword: `${props.getGlobalizedText("regist.msg.err.password.too.short")} ${config.pwdMinLength} ${props.getGlobalizedText("regist.msg.err.password.suffix")}` } };
			} else if (trim(values.newPassword).length > config.pwdMaxLength) {
				res = { ...res, ...{ newPassword: `${props.getGlobalizedText("regist.msg.err.password.too.long")} ${config.pwdMaxLength} ${props.getGlobalizedText("regist.msg.err.password.suffix")}` } };
			} else if (PASSWORD_REGEX.test(trim(values.newPassword)) === false) {
				res = { ...res, ...{ newPassword: conf.openIdConnect === false ? "regist.msg.err.password.format.irregular" : "regist.msg.err.aad.password.format.irregular"} };
			}
		}
		if (((isEmptyStr(userID) || config.editSelfProfile) && config.userProfile.needUpdatePwd) || !(isEmptyStr(values.currentPassword) && isEmptyStr(values.newPassword) && isEmptyStr(values.cfmPassword))) {
			if (isEmptyStr(values.cfmPassword)) {
				res = { ...res, ...{ cfmPassword: "forgpass.msg.err.confirm.password.empty" } };
			} else if (trim(values.newPassword) !== trim(values.cfmPassword)) {
				res = { ...res, ...{ cfmPassword: "regist.msg.err.password.confirm.failured" } };
			}
		}
		if(conf.openIdConnect === false){
			if (isEmptyStr(values.secImage)) {
				res = { ...res, ...{ secImage: "regist.msg.err.sec.img.empty" } };
			}
			if (isEmptyStr(values.secCaption)) {
				res = { ...res, ...{ secCaption: "regist.msg.err.sec.phrase.empty" } };
			} else if (trim(values.secCaption).length > 30) {
				res = { ...res, ...{ secCaption: "regist.msg.err.sec.phrase.too.long" } };
			}	
		}
		if ((isShowError(values) || isEmptyStr(userID)) && isInArray(config.userProfile.userTypeRegCode, ["02", "03", "04", "05", "06", "08", "09", "13"])) {
			if (isEmptyStr(values.securityQuestion1) && isEmptyStr(userID)) {
				res = { ...res, ...{ securityQuestion1: "regist.msg.err.question1.empty" } };
			}
			if (isEmptyStr(values.answer1) && isEmptyStr(userID)) {
				res = { ...res, ...{ answer1: "regist.msg.err.answer1.empty" } };
			} else if (trim(values.answer1).length > 100) {
				res = { ...res, ...{ answer1: "regist.msg.err.answer1.too.long" } };
			}
			if (isEmptyStr(values.securityQuestion2) && isEmptyStr(userID)) {
				res = { ...res, ...{ securityQuestion2: "regist.msg.err.question2.empty" } };
			}
			if (isEmptyStr(values.answer2) && isEmptyStr(userID)) {
				res = { ...res, ...{ answer2: "regist.msg.err.answer2.empty" } };
			} else if (trim(values.answer2).length > 100) {
				res = { ...res, ...{ answer2: "regist.msg.err.answer2.too.long" } };
			}
			if (isEmptyStr(values.securityQuestion3) && isEmptyStr(userID)) {
				res = { ...res, ...{ securityQuestion3: "regist.msg.err.question3.empty" } };
			}
			if (isEmptyStr(values.answer3) && isEmptyStr(userID)) {
				res = { ...res, ...{ answer3: "regist.msg.err.answer3.empty" } };
			} else if (trim(values.answer3).length > 100) {
				res = { ...res, ...{ answer3: "regist.msg.err.answer3.too.long" } };
			}
		}
		if (isEmptyStr(values.securityCode)) {
			res = { ...res, ...{ securityCode: "regist.msg.err.captcha.empty" } };
		} else {
			res.securityCode = captcha.fieldValidate(values.securityCode);
			//res = { ...res, ...{ securityCode: "regist.msg.err.captcha.invalid" } };
		}
		return res;
	}
	let companyCode: string = '';
	if (isEmptyStr(config.userProfile.companyCode)) {
		if (config.companyList && config.companyList.length > 0) {
			companyCode = config.companyList[0].value;
		}
	} else {
		companyCode = config.userProfile.companyCode;
	}

	return <React.Fragment>
		<UnlockUser {...props} regConfig={config} messageService={messageService} validateProps={validateProps} setIsHideUnlock={setIsHideUnlock} />
		{config.userProfile.locked && !isHideUnlock && <>
			<div className="alert gwp-page-message alert-danger">
				<span className="glyphicon glyphicon-exclamation-sign marginright10px" aria-hidden="true" />
				<><GlobalizedText message="profile.msg.usr.lock.out" /><props.LinkButton className="fontred" onClick={() => {
					props.messageService.clearMessage();
					props.showModal("#unlockUser_popup");
				}} ><GlobalizedText message="profile.lbl.lock.user" /></props.LinkButton></>
			</div>
		</>}
		<Formik initialValues={{
			...InitialProfile,
			...config.userProfile,
			userType: config.userProfile.userTypeDesc,
			category: config.userProfile.secImg.category.name,
			secImage: config.userProfile.secImg.name,
			disabled: config.userProfile.disabled ? 'true' : 'false',
			companyCode: companyCode,
			birthDate: config.userProfile.formattedBirthDate,
			polNumberOrMemberID: config.userProfile.polNumberOrMemberID,
			agentSupport: agentSupp,
			dependentSupp: dependentSupp,
			groupAdminSupport: groupAdminSupp,
			guestSupport: guestSupp,
			memberSupport: memberSupp,
			providerSupport: providerSupp,
			userTypeRegCode: config.userProfile.userTypeRegCode
		}}
			{...{ ...props.generateNoramlFormikProps(), ...{ onReset: props.clearMessage } }}
			validate={values => { return validate(validationProfile, values, props); }}
			onSubmit={(values: any, formikHelpers: any) => {
				let paramsMyProfile = copyObjectExcept(copyObjectExcept(values, true, "disabled"), true, "userID");
				//let paramsMyProfile = copyObjectExcept(values, true, "disabled");
				let params = (isEmptyStr(userID) || config.editSelfProfile) ? paramsMyProfile : values;
				let data = {
					...trimFields(params), agentSupport: agentSupp,
					secImg: {
						category: {
							msgKey: values.category,
							name: values.category
						},
						msgKey: values.secImage,
						name: values.secImage
					},
					dependentSupp: dependentSupp,
					groupAdminSupport: groupAdminSupp,
					guestSupport: guestSupp,
					memberSupport: memberSupp,
					providerSupport: providerSupp,
					userTypeRegCode: config.userProfile.userTypeRegCode
				};
				ajax({
					url: '/api/profile/updateProfile',
					data: data,
					method: 'post',
					success: (res_s: any) => {
						props.showMessage("success", "profile.msg.usr.prof.succ");
						if (isEmptyStr(userID) || config.editSelfProfile) {
							formikHelpers.setFieldValue("currentPassword", "");
							formikHelpers.setFieldValue("newPassword", "");
						}
						formikHelpers.setFieldValue("cfmPassword", "");
						formikHelpers.setFieldValue("securityCode", "");

						ajax({
							url: '/api/profile/view',
							params: { userID: userID },
							success: (res_view: any) => {
								setConfig(res_view);
								setAgentSupp(res_view.userProfile.agentSupport === undefined ? agentSupp : res_view.userProfile.agentSupport);
								setDependentSupp(res_view.userProfile.dependentSupport === undefined ? dependentSupp : res_view.userProfile.dependentSupport);
								setGroupAdminSupp(res_view.userProfile.groupAdminSupport === undefined ? groupAdminSupp : res_view.userProfile.groupAdminSupport);
								setGuestSupp(res_view.userProfile.guestSupport === undefined ? guestSupp : res_view.userProfile.guestSupport);
								setMemberSupp(res_view.userProfile.memberSupport === undefined ? memberSupp : res_view.userProfile.memberSupport);
								setProviderSupp(res_view.userProfile.providerSupport === undefined ? providerSupp : res_view.userProfile.providerSupport);
							}
						});
					},
					fail: (data, message) => {
						var failMessage = props.getGlobalizedText(INVALID_MESSAGES.FORGOT_PASSWORD_IN_HISTORY)
							+ ' ' + data.substr(data.indexOf("/") + 1) + ' '
							+ props.getGlobalizedText(INVALID_MESSAGES.FORGOT_PASSWORD_IN_HISTORY_SUFFIX);
						let result: any = {};
						result.newPassword = failMessage;
						result.cfmPassword = failMessage;
						formatError(data, formikHelpers);
						props.showFieldError(failMessage, result, formikHelpers);
					},
					error: (error) => {
						if((error.response.data.data).toUpperCase().includes("regist.msg.err.email.exist".toUpperCase())){
							let result: any = {};
							result['email'] = "regist.msg.err.email.exist";
							props.showFieldError("homepage.errorFields", result, formikHelpers);
						}else{
							props.showMessage("error", error.response.data.message);
						}
					}
				});
			}}
		>
			{formProps => <Form id="editMyForm" name="editMyForm" method="post">
				<props.Row>
					<props.Col md="12" sm="12" xs="12">
						<props.TextControl condition={!isEmptyStr(userID)} disabled={true} id="userType" name="userType" label="security.lbl.userType" value={decodeAmpersand(config.userProfile.userTypeDesc)} />
					</props.Col>
				</props.Row>
				<props.Row >
					<props.Col md="6" sm="6" xs="12">
						<props.Condition condition={!isEmptyStr(userID) && !config.editSelfProfile}>
							<div className="gwp-input margintop10px">
								<props.Row>
									<props.Col md="4" sm="4" xs="12">
										<div className="form-group ">
											<props.Label ><GlobalizedText message="profile.lbl.disabled" /></props.Label>
										</div>
									</props.Col>
									<props.Col md="4" sm="4" xs="6">
										<props.RadioControl id="radDisabledYes" label="profile.lbl.yes" name="disabled" value='true' ></props.RadioControl>
									</props.Col>
									<props.Col md="4" sm="4" xs="6">
										<props.RadioControl id="radDisabledNo" label="profile.lbl.no" name="disabled" value='false'></props.RadioControl>
									</props.Col>
								</props.Row>
							</div>
						</props.Condition>

					</props.Col>
					<props.Col md="6" sm="6" xs="12">
						<props.Condition condition={(config.userProfile.userTypeRegCode === '09' || config.userProfile.userTypeRegCode === '05') && (!isEmptyStr(userID))}>
							<props.Link to={"/accessPermissionCompanies/" + userID} >
								<props.Icon type="lock" label="common.lbl.accesspermissions"></props.Icon>
							</props.Link>
						</props.Condition>
					</props.Col>
				</props.Row>
				<props.Panel className="gwp-table">
					<props.PanelHeading>
						<span className="panel-title"><GlobalizedText message="common.lbl.personalInformation" /></span>
					</props.PanelHeading>
					<props.PanelBody>
						<props.Row>
							<props.Col md="6" sm="6" xs="12">
								<props.TextControl id="firstName" name="firstName" label="common.lbl.firstName"
									required={(isEmptyStr(userID) && isInArray(config.userProfile.userTypeRegCode, ["01", "10"]))
										|| (!isEmptyStr(userID) && isInArray(config.userProfile.userTypeRegCode, ["01", "05", "09", "10", "11", "12"]))}
									disabled={!((isEmptyStr(userID) && isInArray(config.userProfile.userTypeRegCode, ["01", "10"])) || (!isEmptyStr(userID) && isInArray(config.userProfile.userTypeRegCode, ["01", "05", "09", "10", "11", "12"])))}
									condition={!(isInArray(config.userProfile.userTypeRegCode, ["03", "08"]))}
								/>
								<props.TextControl id="lastName" name="lastName" label="common.lbl.lastName"
									required={(isEmptyStr(userID) && isInArray(config.userProfile.userTypeRegCode, ["01", "10"]))
										|| (!isEmptyStr(userID) && isInArray(config.userProfile.userTypeRegCode, ["01", "05", "09", "10", "11", "12"]))}
									disabled={!((isEmptyStr(userID) && isInArray(config.userProfile.userTypeRegCode, ["01", "10"]))
										|| (!isEmptyStr(userID) && isInArray(config.userProfile.userTypeRegCode, ["01", "05", "09", "10", "11", "12"])))}
									condition={!(isInArray(config.userProfile.userTypeRegCode, ["03", "08"]))}
								/>
								<props.TextControl id="phoneNumber" name="phoneNumber" label="regist.label.phone"
									required={false}
									disabled={!(isInArray(config.userProfile.userTypeRegCode, ["01", "05", "09", "10", "11", "12"]))}
									condition={isInArray(config.userProfile.userTypeRegCode, ["01", "05", "09", "10", "11", "12"])}
								/>
								<props.TextControl id="orgName" name="orgName" label={config.userProfile.userTypeRegCode === "03" ? "changeow.lbl.companyName" : "regist.label.national.company.name"}
									required={false}
									disabled={true}
									condition={isInArray(config.userProfile.userTypeRegCode, ["03", "04"])}
								/>
								<props.TextControl id="polNumberOrMemberID" name="polNumberOrMemberID" label="common.lbl.memberIdentifierPolicyNumber"
									required={false}
									disabled={true}
									condition={config.userProfile.userTypeRegCode === "03"}
								/>
								<props.DateTimePickerControl htmlFor="birthDate_input" id="birthDate" name="birthDate" label="common.lbl.dob" dateformat={config.userProfile.userDateFormatLower}
									required={false}
									disabled={true}
									condition={config.userProfile.userTypeRegCode === "06"}
								/>
								<props.TextControl id="agencyName" name="agencyName" label="regist.label.agency.name"
									required={false}
									disabled={true}
									condition={config.userProfile.userTypeRegCode === "08"} />
								<props.TextControl id="agencyNumber" name="agencyNumber" label="regist.label.agency.number"
									required={false}
									disabled={true}
									condition={config.userProfile.userTypeRegCode === "08"}
								/>
								<props.TextControl id="email" name="email" label="regist.label.email"
									//readonly onfocus="this.removeAttribute('readonly')" 
									autoComplete="off"
									required={conf.openIdConnect ? checkEmailRequired(config.userProfile): false}
									disabled={disableEmail(conf.openIdConnect , isInArray(config.userProfile.userTypeRegCode, ["01", "05", "09", "10", "11", "12"]))}
									condition={showEmail(conf.openIdConnect , isInArray(config.userProfile.userTypeRegCode, ["01", "05", "09", "10", "11", "12"]))}
								/>								
							</props.Col>
							<props.Col md="6" sm="6" xs="12">
								<props.DateTimePickerControl htmlFor="birthDate_input" id="birthDate" name="birthDate" label="common.lbl.dob" dateformat={config.userProfile.userDateFormatLower}
									required={false}
									disabled={true}
									condition={isInArray(config.userProfile.userTypeRegCode, ["02", "13"])}
								/>
								<props.TextControl id="polNumberOrMemberID" name="polNumberOrMemberID" label="common.lbl.memberIdentifierPolicyNumber"
									required={false}
									disabled={true}
									condition={isInArray(config.userProfile.userTypeRegCode, ["02", "13"])}
								/>
								<props.TextControl id="nationProvideID" name="nationProvideID" label="regist.label.national.provider.id"
									required={false}
									disabled={true}
									condition={config.userProfile.userTypeRegCode === "04"}
								/>
								<props.TextControl id="jobTitle" name="jobTitle" label="regist.label.jobtitle"
									required={false}
									disabled={isEmptyStr(userID)}
									condition={isInArray(config.userProfile.userTypeRegCode, ["05", "09"])}
								/>
								<props.TextControl id="agentNumber" name="agentNumber" label="common.lbl.agentNumber"
									required={false}
									disabled={true}
									condition={config.userProfile.userTypeRegCode === "06"}
								/>
							</props.Col>
						</props.Row>

					</props.PanelBody>
				</props.Panel>
				<props.Panel>
					<props.PanelHeading>
						<span className="panel-title"><GlobalizedText message="regist.label.login.and.security.information" /></span>
					</props.PanelHeading>
					<props.PanelBody>
						<props.Row>
							<props.Col md="6" sm="6" xs="12">
								<props.TextControl id="userID" name="userID" disabled={true} required={false} label="groupAdmin.userID" />
								{conf.openIdConnect === false && <props.PasswordControl disabled={!isEmptyStr(userID) && !config.editSelfProfile} required={config.userProfile.needUpdatePwd && (isEmptyStr(userID) || config.editSelfProfile)} id="currentPassword" name="currentPassword" label="groupAdmin.currentPassword" />}
								<props.PasswordControl condition={!config.userProfile.locked} required={config.userProfile.needUpdatePwd && isEmptyStr(userID)} id="newPassword" name="newPassword" label="groupAdmin.newPassword" />
								<props.PasswordControl condition={!config.userProfile.locked} required={config.userProfile.needUpdatePwd && isEmptyStr(userID)} id="cfmPassword" name="cfmPassword" label="regist.label.confirm.password" />
								{conf.openIdConnect === false &&  <props.Row>
									<props.Col md="8" xs="12">
										<props.Panel className="gwp-table">
											<props.PanelHeading>
												<GlobalizedText message="regist.label.sec.img" />
											</props.PanelHeading>
											<props.PanelBody>
												<props.SecurityImagesCategoryControl imageName="secImage" categoryName="category" categories={config.categorys} images={config.securityImages} {...props}></props.SecurityImagesCategoryControl>
											</props.PanelBody>
										</props.Panel>
									</props.Col>
								</props.Row> }
								{conf.openIdConnect === false &&  <props.TextControl required={true} label="regist.label.sec.img.caption" id="secCaption" name="secCaption" ></props.TextControl> }
								{conf.openIdConnect && <props.SelectControl name="mfaAuth" label="profile.lbl.mfa"
									required={false} sort={false}
									options={config.mfaTypes}
									disabled={!(userAuth.userTypeRegCode && allowedUserTypes.includes(userAuth.userTypeRegCode))}
								/>}								
							</props.Col>
							<props.Col md="6" sm="6" xs="12">

								<props.SelectControl name="securityQuestion1" label="regist.label.question1"
									required={isEmptyStr(userID)} sort={false}
									options={addFirstOption(config.securityQuestions.map((item: any) => { if (item.questionID !== formProps.values.securityQuestion2 && item.questionID !== formProps.values.securityQuestion3) return { name: item.question, value: item.questionID } }), props.getGlobalizedText)}
									condition={isInArray(config.userProfile.userTypeRegCode, ["02", "03", "04", "05", "06", "08", "09", "13"])}
								/>
								<props.TextControl id="answer1" name="answer1" label="regist.label.answer1"
									required={isEmptyStr(userID)}
									condition={isInArray(config.userProfile.userTypeRegCode, ["02", "03", "04", "05", "06", "08", "09", "13"])}
								/>
								<props.SelectControl name="securityQuestion2" label="regist.label.question2"
									required={isEmptyStr(userID)} sort={false}
									options={addFirstOption(config.securityQuestions.map((item: any) => { if (item.questionID !== formProps.values.securityQuestion1 && item.questionID !== formProps.values.securityQuestion3) return { name: item.question, value: item.questionID } }), props.getGlobalizedText)}
									condition={isInArray(config.userProfile.userTypeRegCode, ["02", "03", "04", "05", "06", "08", "09", "13"])}
								/>
								<props.TextControl id="answer2" name="answer2" label="regist.label.answer2"
									required={isEmptyStr(userID)}
									condition={isInArray(config.userProfile.userTypeRegCode, ["02", "03", "04", "05", "06", "08", "09", "13"])}
								/>
								<props.SelectControl name="securityQuestion3" label="regist.label.question3"
									required={isEmptyStr(userID)} sort={false}
									options={addFirstOption(config.securityQuestions.map((item: any) => { if (item.questionID !== formProps.values.securityQuestion1 && item.questionID !== formProps.values.securityQuestion2) return { name: item.question, value: item.questionID } }), props.getGlobalizedText)}
									condition={isInArray(config.userProfile.userTypeRegCode, ["02", "03", "04", "05", "06", "08", "09", "13"])}
								/>
								<props.TextControl id="answer3" name="answer3" label="regist.label.answer3"
									required={isEmptyStr(userID)}
									condition={isInArray(config.userProfile.userTypeRegCode, ["02", "03", "04", "05", "06", "08", "09", "13"])}
								/>
								<props.TextControl id="securityCode" name="securityCode" required={true} label="regist.label.enter.captcha" />
								<captcha.Component></captcha.Component>
							</props.Col>
						</props.Row>
					</props.PanelBody>
				</props.Panel>
				<UserInquiryAssignmentComponent {...props} setAgentSupp={ setAgentSupp } agentSupp={ agentSupp } setDependentSupp={ setDependentSupp } dependentSupp={ dependentSupp } setGroupAdminSupp={ setGroupAdminSupp } groupAdminSupp={ groupAdminSupp } setGuestSupp={ setGuestSupp } guestSupp={ guestSupp } setMemberSupp={ setMemberSupp } memberSupp={ memberSupp } setProviderSupp={ setProviderSupp } providerSupp={ providerSupp } isEmptyStr={ isEmptyStr } userTypeRegCode={ config.userProfile.userTypeRegCode } userID={ userID } />
				<props.Panel>
					<props.PanelHeading>
						<span className="panel-title"><GlobalizedText message="regist.label.preference.information" /></span>
					</props.PanelHeading>
					<props.PanelBody>
						<props.Row>
							<props.Col md="6" sm="6" xs="12">
								<props.SelectControl name="languageCode" label="regist.label.pref.language" required={false} options={config.perLanguages} />
							</props.Col>
							<props.Col md="6" sm="6" xs="12">
								<props.SelectControl name="dateFormat" label="regist.label.pref.date.format" required={false} options={config.prefDateFormats} />
							</props.Col>
						</props.Row>
					</props.PanelBody>
				</props.Panel>
				<props.Row>
					<props.Col sm='12'>
						<props.Button type="submit" onClick={() => {
							document.documentElement.scrollTop = document.body.scrollTop = 0;
						}}><GlobalizedText message="common.button.submit" /> </props.Button>
						<props.Button className="cancelBtn" onClick={() => { props.back() }}><GlobalizedText message="common.lbl.cancel" /> </props.Button>
					</props.Col>
				</props.Row>
			</Form>
			}
		</Formik>
	</React.Fragment>
});

const Or = (props: ViewComponentProps) => {
	return <props.Div className="margintop10px"><GlobalizedText message="common.lbl.or" /></props.Div>
}

const addFirstOption = (array: Array<any>, getGlobalizedText: (key: string) => string): Array<any> => {
	let result = new Array<any>();
	result.push({ "name": getGlobalizedText("common.lbl.pleaseselect"), "value": "" })
	for (let a of array) {
		if (a !== undefined && a.name !== undefined)
			result.push(a);
	}
	return result;
}
const isInArray = (key: string, array: Array<string>): boolean => {
	for (let a of array) {
		if (a === key)
			return true
	}
	return false;
}

const checkEmailRequired = (userPorfile : any): boolean => {
	if(userPorfile.email == "" || userPorfile.email == null || userPorfile.email == undefined){
		return false
	}else{
		return true
	}

}

const showEmail = (openConnect: boolean , isInArray: boolean): boolean => {
	if(openConnect){
		return true
	}else{
		return isInArray
	}
}

const disableEmail = (openConnect: boolean , isInArray: boolean): boolean => {
	if(openConnect){
		return false
	}else{
		return !isInArray
	}
}


const isShowError = (values: any): boolean => {
	if (isEmptyStr(values.securityQuestion1) && isEmptyStr(values.securityQuestion2) && isEmptyStr(values.securityQuestion3) && isEmptyStr(values.answer1) && isEmptyStr(values.answer2) && isEmptyStr(values.answer3)) {
		return false;
	} else {
		return true;
	}
}

const QUESTION_MAX_LENGTH: number = 100;
const textWidthStyle = {
	maxWidth: '66%'
}
interface ModalProps extends ViewComponentProps {
	regConfig: any;
	validateProps: any;
	setIsHideUnlock: any;
}


const UnlockUser = (props: ModalProps) => {
	const userID: string = props.getParam("userID");
	const PASSWORD_REGEX: RegExp = new RegExp(props.regConfig.validationRule);
	let initialValues = { ...{ password: '', cPassword: '' } }
	let fp = {
		initialValues: initialValues,
		onSubmit: (values: any, formikHelpers: any) => {
			ajax({
				url: '/api/profile/unlockUser',
				data: {
					userID: userID,
					newPassword: values.password
				},
				method: 'post',
				success: (res_unlock: any) => {
					formikHelpers.resetForm();
					props.setIsHideUnlock(true);
					closeModal("#unlockUser_popup", () => { props.showMessage("success", "profile.msg.usr.unlock.succ"); });
				}, showMask: false
			});
		},
		validate: (values: any) => {
			let unlockUserSchema = () => {
				let res: any = {};
				if (isEmptyStr(values.password)) {
					res = { ...res, ...{ password: "regist.msg.err.new.password.empty" } };
				} else if (trim(values.password).length < props.regConfig.pwdMinLength) {
					res = { ...res, ...{ password: `${props.getGlobalizedText("regist.msg.err.password.too.short")} ${props.regConfig.pwdMinLength} ${props.getGlobalizedText("regist.msg.err.password.suffix")}` } };
				} else if (trim(values.password).length > props.regConfig.pwdMaxLength) {
					res = { ...res, ...{ password: `${props.getGlobalizedText("regist.msg.err.password.too.long")} ${props.regConfig.pwdMaxLength} ${props.getGlobalizedText("regist.msg.err.password.suffix")}` } };
				} else if (PASSWORD_REGEX.test(trim(values.password)) === false) {
					res = { ...res, ...{ password: "regist.msg.err.password.format.irregular" } };
				}
				if (isEmptyStr(values.cPassword)) {
					res = { ...res, ...{ cPassword: "forgpass.msg.err.confirm.password.empty" } };
				} else if (trim(values.password) !== trim(values.cPassword)) {
					res = { ...res, ...{ cPassword: "regist.msg.err.password.confirm.failured" } };
				}
				return res;
			}
			return validate(unlockUserSchema, values, props.validateProps);
		},
		validateOnBlur: false,
		validateOnChange: false
	};

	return <>
		<Formik onReset={() => { props.messageService.clearMessage() }} {...fp}>
			{formProps =>
				<Form>
					<props.Modal id="unlockUser_popup" title="profile.lbl.set.new.pwd" footer={() => {
						return <>
							<Button type="submit"><GlobalizedText message="common.lbl.submit" /></Button>

							<props.Button onClick={() => { formProps.resetForm(); props.closeModal("#unlockUser_popup"); }}><GlobalizedText message="common.lbl.cancel" /></props.Button>
						</>
					}} messageService={props.messageService} >
						<props.Row>
							<props.Col sm="12">
								<props.PasswordControl required={true} id="password" name="password" label="groupAdmin.newPassword" />
							</props.Col>
							<props.Col sm="12">
								<props.PasswordControl required={true} id="cPassword" name="cPassword" label="regist.label.confirm.password" />
							</props.Col>
						</props.Row>
					</props.Modal>
				</Form>
			}
		</Formik>
	</>
}