import { Form, Formik, } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import GlobalizedText, { GlobalizationContext } from '../shared/globalization';
import { ajax, $, mergeObject, isEmptyStr, getGlobalizedText } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/multifactorAuthentication.css';

interface MultifactorAuthenticationForm {
    userType: string;
    companyCode: string;
    groupNumber: string;
    accountNumber: number;
    settings: any;
    isAllowedMFASetting: 'no'
}

const ALL_GROUP_NUMBER = '0';

function setDisplayedValue(options: Array<any>, value: string | number, callback: Function) {
    for (let option of options) {
        if (option.value === value) {
            callback(option.name);
        }
    }
}
let initialValue = [
    { function: 'Multifactor Authentication', mfaRequired: false }
];
export const MultifactorAuthenticationComponent = withView((props: ViewComponentProps) => {
    const varFormPromp = useRef<any>();
    const [fucntionMfaIsRequired, setFucntionMfaIsRequired] = useState<boolean>(false);
    const [config, setConfig] = useState<any | null>(null);
    const [companyCode, setCompanyCode] = useState<any | null>(null);
    const [editing, setEditing] = useState<boolean>(false);
    const [userTypeName, setUserTypeName] = useState<any | null>('');
    const [data, setData] = useState<any>(initialValue);
    const [userTypeRegCode, setUserTypeRegCode] = useState<string>('');
    const [isAllowedMFASettings, setIsAllowedMFASettings] = useState<boolean>(false);
    const [isAllowedMFASettingValue, setIsAllowedMFASettingValue] = useState<boolean>(false);
    const [isMFARequired, setIsMFARequired] = useState<boolean>(false);
    function clickEditOnSystem() {
        setEditing(true);
        props.clearMessage();
    }
    function clickCloseOnSystem() {
        setEditing(false);
    }
    /**
     * @param userTypeCode      user type code
     */
    function loadData(userTypeCode: string) {
        ajax({
            url: `/api/mfamanagement/userType/${userTypeCode}`,
            success: (res: any) => {
                setData(initialValue.map(item => ({ ...item, mfaRequired: res || false })));
            }
        });
    }

    const formSubmit = (values: MultifactorAuthenticationForm, { setSubmitting }: any) => {
        const url = isAllowedMFASettingValue
            ? `/api/mfamanagement/updateGroups/${userTypeRegCode}/${values.companyCode}`
            : `/api/mfamanagement/updateUserType/${userTypeRegCode}/${fucntionMfaIsRequired}`;

        const dataPayload = isAllowedMFASettingValue
            ? { userTypeID: userTypeRegCode, companyCode: values.companyCode, groupmfaRequiredSettings: data }
            : { userTypeID: userTypeRegCode, isMfaRequired: data };

        ajax({
            method: 'put',
            url,
            data: dataPayload,
            success: () => props.showMessage("success", props.getGlobalizedText("common.msg.updateSuccess")),
            fail: (res: any) => props.showMessage("error", res.message)
        });
    }

    let initialValues: MultifactorAuthenticationForm = {
        userType: '10003',
        companyCode: 'CO1',
        groupNumber: ALL_GROUP_NUMBER,
        accountNumber: 0,
        settings: {},
        isAllowedMFASetting: 'no'
    }
    const showGroupSpecific = (e: any) => {
        let userTypeCode = e.target.value;
        const allowedUserTypeMFASetting = ["10007", "10005", "10002", "10012", "10001", "10004"];
        if (allowedUserTypeMFASetting.includes(userTypeCode)) {
            setIsAllowedMFASettings(true);
            setIsAllowedMFASettingValue(false);
        } else {
            setIsAllowedMFASettings(false);
            setIsAllowedMFASettingValue(false);
        }
    }
    const handleUserTypeChange = (e: any, formProps: any) => {
        setDisplayedValue(userTypeOptions, parseInt(`${e.target.value}`), setUserTypeName);
        props.resetForm(formProps, mergeObject(formProps.values, { userType: e.target.value }));
        loadData(e.target.value);
        setUserTypeRegCode(e.target.value);
        setIsAllowedMFASettingValue(false);
        props.clearMessage();
    }
    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/mfamanagement/view',
                success: (res: any) => {
                    setConfig(res);
                    loadData('10003');
                    setCompanyCode('CO1');
                    setUserTypeRegCode('10003');
                    let utos = res.userTypes.map((ut: any) => {
                        return { name: ut.userTypeName, value: ut.userTypeCode }
                    });
                    setDisplayedValue(utos, res.defaultUserType, setUserTypeName);
                }
            });
        }
    }, [config, data])
    if (config === undefined || config === null) {
        return <></>;
    }
    let userTypeOptions = config.userTypes
        .filter((ut: any) => ut.userTypeCode.toString() !== '10007')
        .map((ut: any) => {
            return (ut.userTypeCode.toString() === '10005') ? { name: ut.userTypeName.trim() +'/'+ props.getGlobalizedText("common.lbl.agency"), value: ut.userTypeCode } : { name: ut.userTypeName, value: ut.userTypeCode }
        });
    const retriveByCompanyAndUsertype = () => {
        if (!isEmptyStr(companyCode) && !isEmptyStr(userTypeRegCode)) {
            ajax({
                url: `/api/mfamanagement/groups/${userTypeRegCode}/${companyCode}`,
                success: (res: any) => {
                    setData(res);
                }
            });
        }
    }

    const retriveMFAFilter = () => {
        if (isEmptyStr(varFormPromp.current.values.searchGroupNumberOrName)) {
            retriveByCompanyAndUsertype();
        } else {
            ajax({
                url: `/api/mfamanagement/groups/${userTypeRegCode}/${companyCode}/${varFormPromp.current.values.searchGroupNumberOrName}`,
                success: (res: any) => {
                    setData(res);
                }
            });
        }
    }

    const onClickHandelWithoutGroupMFAAccess = (row: any, e: any) => {
        setData((prevData: any) => prevData.map((row: any) => {
            return { ...row, mfaRequired: !fucntionMfaIsRequired };
        }));
        setFucntionMfaIsRequired(!fucntionMfaIsRequired);
    }
    const onClickHandelWithGroupMFAAccess = (row: any, e: any) => { 
        setData((prevData: any) => {
            const newData = [...prevData];
            newData[e] = {...newData[e], mfaRequired: !newData[e].mfaRequired}
            return newData;
        });
    }
    const selectAll = () => {
        setData((prevData: any) => prevData.map((row: any) => {
            return { ...row, mfaRequired: true };
        }));
    }

    function deselectAll() {
        setData((prevData: any) => prevData.map((row: any) => {
            return { ...row, mfaRequired: false };
        }));
    }
    function saveSetting() {
    }
    const onCheckedHandeler = () => {
        setIsMFARequired(!isMFARequired);
    }
    if (isAllowedMFASettingValue) {
        initialValues = { ...initialValues, isAllowedMFASetting: 'no' };
    }
    return (
        <>
            {/* MFA Filter */}
            <Formik
                initialValues={initialValues}
                onSubmit={retriveMFAFilter}
            >
                {formProps => {
                    varFormPromp.current = formProps;
                    return <Form>
                        <props.Container>
                            <props.Row>
                                <props.Col>
                                    <props.SelectControl options={userTypeOptions} onChange={(e: any) => { handleUserTypeChange(e, formProps); showGroupSpecific(e); formProps.setFieldValue("isAllowedMFASetting", 'no') }} name="userType" id="userType" label="security.lbl.userType"></props.SelectControl>
                                </props.Col>
                            </props.Row>
                            {isAllowedMFASettings &&
                                <props.Row className='mt-3'>
                                    <props.Col>
                                        <props.Label htmlFor="mfaSettings" required={true}><GlobalizedText message='common.lbl.mfaSetting' /></props.Label>
                                        <props.RadioControl onChange={() => {
                                            setIsAllowedMFASettingValue(true);
                                            retriveByCompanyAndUsertype();
                                            setData(null);
                                        }} containerClassName="gwp-inline" name="isAllowedMFASetting" id="mfaYes" label="Yes" value="yes"></props.RadioControl>
                                        <props.RadioControl onChange={() => {
                                            setIsAllowedMFASettingValue(false);
                                            loadData(formProps.values.userType);
                                            setData(null);
                                        }} containerClassName="gwp-inline" name="isAllowedMFASetting" id="mfaNo" label="No" value="no"></props.RadioControl>
                                    </props.Col>
                                </props.Row>
                            }
                            {
                                isAllowedMFASettingValue && <>
                                    <props.Row>
                                        <props.Col>
                                            <props.SelectControl name='company' options={config.companies.map((company: any) => {
                                                return { name: company.fullCompanyName, value: company.companyCode }
                                            })} required={true} onChange={(e: any) => setCompanyCode(e.target.value)} label='memlist.company' ></props.SelectControl>
                                            <props.TextControl name='searchGroupNumberOrName' label='common.lbl.searchGroupNumber' required={true}></props.TextControl>
                                            <props.Button type='submit'><GlobalizedText message='common.lbl.retrieve'></GlobalizedText></props.Button>
                                        </props.Col>
                                    </props.Row>
                                </>
                            }
                        </props.Container>
                    </Form>
                }
                }
            </Formik>
            {/* MFA Settings Table */}
            <Formik
                initialValues={initialValues}
                onSubmit={formSubmit}
            >
                {formProps =>
                    <Form>
                        <props.Container>
                            <props.Row>
                                <props.Col>
                                    <div id="scTabContent" className="tab-content">
                                        <div role="tabpanel" className={`tab-pane fade in active`} id="systemLevelTab">
                                            {!isAllowedMFASettingValue && <div id="sysButtons" style={{ marginBottom: "20px" }}>
                                                {editing !== true &&
                                                    <props.Button onClick={clickEditOnSystem}><GlobalizedText message="common.lbl.edit" /></props.Button>

                                                }
                                                {editing === true &&
                                                    <React.Fragment>
                                                        <props.Button type="submit"><GlobalizedText message="common.button.submit" /></props.Button>
                                                        <props.Button onClick={clickCloseOnSystem}><GlobalizedText message="common.lbl.close" /></props.Button>
                                                    </React.Fragment>
                                                }
                                            </div>}
                                            <div>
                                                {!isAllowedMFASettingValue ?
                                                    <WithoutGroupDataTable {...props} data={data} onClickHandelMFAAccess={onClickHandelWithoutGroupMFAAccess} isEditing={editing} fucntionMfaIsRequired={fucntionMfaIsRequired}/> :
                                                    <WithGroupDataTable {...props} data={data} onClickHandelMFAAccess={onClickHandelWithGroupMFAAccess} isEditing={editing} />}

                                            </div>
                                            {isAllowedMFASettingValue &&
                                                <props.Row>
                                                    <props.Col sm="12" className="form-inline">
                                                        <props.Button id="selectAll" onClick={() => selectAll()}><GlobalizedText message="common.lbl.selectAll" /></props.Button>
                                                        <props.Button id="deselectAll" onClick={() => deselectAll()}><GlobalizedText message="common.lbl.deselectAll" /></props.Button>
                                                        <props.Button id="save" type="submit" disabled={false} onClick={() => saveSetting()}><GlobalizedText message="common.lbl.save" /></props.Button>

                                                    </props.Col>
                                                </props.Row>}
                                        </div>
                                    </div>
                                </props.Col>
                            </props.Row>
                        </props.Container>
                    </Form>
                }
            </Formik>
        </>
    )
});


export const WithoutGroupDataTable = (props: any) => {
    const globalizatin = useContext(GlobalizationContext);
    return <>
        <props.Table id="withoutGroupDataTable" >
            <thead>
                <tr>
                    <th scope="col"><GlobalizedText message='security.lbl.function' /></th>
                    <th scope="col"><GlobalizedText message="security.lbl.access" /></th>
                </tr>
            </thead>
            <tbody>
                {props.data != null && props.data.map((row: any,index:number) => {
                    return <tr key={index}>
                        <td>{row.function}</td>
                        <td>{(props.isEditing !== true) ?
                            (row.mfaRequired) ? getGlobalizedText(globalizatin, "common.mfa.enable") : getGlobalizedText(globalizatin, "common.mfa.disable") : (
                                <input type='checkbox' checked={row.mfaRequired} onChange={(e) => props.onClickHandelMFAAccess(row, e)} />
                            )
                        }
                        </td>
                    </tr>
                })}
            </tbody>
        </props.Table>
    </>;
}
export const WithGroupDataTable = (props: any) => {
    return <>
        <props.Table id="withGroupDataTable">
            <thead>
                <tr>
                    <th scope="col"><GlobalizedText message='common.lbl.groupNumber' /></th>
                    <th scope="col"><GlobalizedText message="pd.lbl.groupname" /></th>
                    <th scope="col"><GlobalizedText message="common.lbl.multifactorAuthentication" /></th>
                </tr>
            </thead>
            <tbody>
                {props.data !== null && props.data.map((row: any, index: number) => {
                    return <tr key={index}>
                        <td>{row.groupNumber}</td>
                        <td>{row.groupName}</td>
                        <td>{
                            <input type='checkbox' checked={row.mfaRequired} onChange={(e) => props.onClickHandelMFAAccess(row, index)} />
                        }
                        </td>
                    </tr>
                })}
            </tbody>
        </props.Table></>;
}